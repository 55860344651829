
.demo-app {
    display: flex;
    /* min-height: 100%; */
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 10px;
  }
  
  .calendars{
    color: black;
    width: 100%;
    height: 100vh;
    margin-top: 15rex;
    text-align: center;
  }



  .fc-direction-ltr{
    width: 80%;
    /* height: 80vh; */
    margin: auto;
    cursor: pointer;
  }


  /* EXTRA */

 