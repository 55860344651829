:root {
    --primaryBlue: #002244;
    --secondryColor: #3A98B9;
    --thirdColor: #6963fe;
    --forthColor: #92dcff;
}

.contact {
    max-width: calc(200px + 85vw);
    height: calc(150px);
    margin-bottom: 20px;
    color: white;
    display: flex;
    font-size: 1.4rem;
    align-items: center;
    justify-content: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact h1{
   text-shadow: 0.5px 0.5px 10px blue;
}

@media all and (max-width:700px) {
    .contact {
        height: calc(70px + 20vh);
    }
}