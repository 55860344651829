.login_main {
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 1rem;

}

.login_mdiv {
    width: 30%;
    background-color: aliceblue;
    padding: 1rem 3rem;
}

.login_slogan {
    text-align: center;
}

.login_slogan img {
    margin: 0 auto;
    width: 12rem;
}

.login_slogan h4 {
    margin: 0.25rem 0 3rem 0;
    padding-bottom: 0.25rem;
}

.login_formdiv input {

    width: 100%;
    padding: 0.675rem 0.75rem;
    font-size: 1rem;
    font-family: normal;
    color: #4b5563;
    background-color: beige;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    margin: 0;

}

.login_passwordiv {
    margin-bottom: 1rem;
}

.login_passwordiv input {
    width: 100%;
    padding: 0.675rem 0.75rem;
    font-size: 1rem;
}

.loginbutton_div {
    padding: 0.25rem 0;
    margin-bottom: 3rem;
    
}

.loginbutton_div button {
    padding: 0.625rem 1.5rem;
    font-size: 0.875rem;
    font-family: Montserrat;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
    margin-bottom: 0.75rem;
    background-color: #F59E0B;
    color: #ffffff;
    cursor: pointer;
}