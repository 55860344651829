:root {
  --primaryBlue: #002244;
  --secondryColor: #3a98b9;
  --thirdColor: #6963fe;
  --forthColor: #92dcff;
  --purpleColor: #2b044d;
}
 
.map {
  width: 100%;
  height: 50%;
  background-color: white;
  margin: 10px auto;
}

.map iframe {
  border-radius: 2px;
  width: 100%;
  height: 100%;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.touch {
  width: 90%;
  height: 8vh;
  background-color: white;
  margin: auto;
  display: flex;
  /* font-size: 1.5rem; */
  color: #2b044d;
  align-items: center;
  font-size: 13px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.info_address {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  height: 65vh;
  width: 94vw;
  margin: auto;

  background-color: #fff;
}

.information {
  width: 54%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* font-size: 18px; */
}

.message {
  width: 95%;
  height: 20vh;
  /* background-color: white;
  border: 1px solid var(--purpleColor);
  border-radius: 20px; */
}
.message textarea{
  background-color: beige;
}
input{
  background-color: beige;
}
.message p {
  margin: 5px;
}

.form_control_textarea {
  width: 100%;
  height: 100%;
  font-size: 20px;
  padding-left: 10px;
}

.address1 {
  width: 37%;
  height: 100%;
  background-color: white;
}
.adressBar{
  height: 45%;
}

.place {
  width: 100%;
  /* height: 50%; */
  background-color: white;
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.place img {
  /* font-size: 50px;
    color: grey; */
  width: 70px;
  height: 45px;
}

.media {
  width: 80%;
  /* height: 12vh; */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.detail {
  width: 90%;
  min-height: 55vh;
  background-color: white;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.detail p {
  margin: 5px;
}

.detail input {
  width: 50vh;
  height: 8vh;
  border: 1px solid var(--purpleColor);
  padding-left: 10px;
}

.detail input[type="submit"] {
  width: 150px;
  height: 40px;
  background-color: var(--purpleColor);
  border-radius: 5px;
  border: 1px solid purple;
  color: white;
  font-size: 18px;
}
.detail input[type="submit"]:hover {
  width: 150px;
  height: 40px;
  background-color: var(--thirdColor);
  border-radius: 5px;
  border: 1px solid thirdColor;
  color: white;
  font-size: 18px;
}

@media screen and (max-width: 850px) {
  .information{
   width: 45%;
  }
 
  .detail input{
   width: 80%;
  }
  .address1{
   width: 55%;
   margin-top: -20px;
  }
 }

@media screen and (max-width: 600px) {
  .info_address {
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: auto;
  }
  .detail input{
    width: 100%;
  }

  .information {
    width: 84%;
    height: auto;
  }

  .message {
    width: 95%;
    height: 40vh;
    background-color: white;
  }
  .address1 {
    margin-top: 2px;
    width: 84%;
    font-size: 13px;
  }
}


