.container {
  width: 100vw;
  height: 100%;
  padding: 0;
  margin-top: 50px;
}
.contentBar { 
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  flex-direction: row;
  width: 100vw;
}
.extraText {
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
}
.contentTextBox {
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
}
.contentText {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  /* align-items: left; */
  flex-direction: column;
}
.contentText .headings h1 {
  font-size: 35px;
  font-weight: 500; 
}
.contentText h1 {
  font-size: 35px;
  font-weight: 500;
}
.contentText h2 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 5px;
}

.contentText p{
  font-size: 16px;
  line-height: 25px;
}
.imgBar {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: left;
}
.imgBar img {
  /* width: 50%; */
  margin-right: 100px;
  border-radius: 20px;
  height: 400px;
}
@media screen and (max-width: 580px) {
  .contentTextBox{
    width: 100%;
  }
  .contentText{
    width: 80%;
  }
  .contentBar{
    flex-direction: column;
  }
  .headings{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgBar img {
    width: 160%;
    margin: 10px;
  }
}

.bootomContainer {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}
.bottemContent {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-items: center;
  text-align: left;
}
.bootemHead {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 10px;
}
.bootemHead h1 {
  font-size: 40px;
  font-weight: 600;
  color: #FFA559;
}
.bootemHead h2 {
  font-size: 30px;
  font-weight: 500;
  margin-right: 10px;
  /* color: #FFA559; */
}


/* .bottemText {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; 
  transition: 1s ease-in-out;
} */

/* .bottemText:hover{
  border-style: solid;
  border-color: black;
  background-color: #8cc63f;

} */
.bottemText {
  width: 80%;
  height: 110%;
  display: flex;
  padding: 20px;
  border-radius: 20px;
  line-height: 25px;
  word-spacing: 4px;
  /* justify-content: center; */
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --c: no-repeat linear-gradient(#1095c1 0 0);
  background: 
    var(--c)  calc(-101% + var(--p,0%)) 100%,
    var(--c)  calc( 201% - var(--p,0%)) 0;
  background-size: 50.1% var(--p,.08em);
  transition: .3s var(--t,0s), background-position .3s calc(.3s - var(--t,0s));
}
.bottemText:hover {
  --p: 101%;
  --t: 0.3s;
  color: #fff;
}

.bottemText ul li {
  text-align:justify ;
  margin-left: 20px;
}

@media screen and (max-width: 580px) {
 
  .bottemContent{
    grid-template-columns: 100%;
  }
  .bottemText{
    margin: 20px 0;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    height: auto;
  }
}
span{
  font-weight: 600;
}

.headings{
  display:flex ;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
}
.headings p{
  text-align: justify;
}
 .heading-line .short-line {
  display: inline-block;
  width: 50px;
  height: 3px;
  background-color: #8cc63f;
  border-radius: 10px;
  position: relative;
  margin-right: 10px;
  transition: all .4s linear;
  /* top:0; right:0;  */
}
.h1{
  font-size: 32px;
}

 .heading-line .long-line {
  display: inline-block;
  width: 110px;
  height: 3px;
  background-color: #8cc63f;
  border-radius: 10px;
  position: relative;
  transition: all .4s linear;
  top:0; right:0; 
}

.headings:hover .short-line {
  animation: short-line-animation 2s infinite linear;
}

.headings:hover .long-line {
  animation: long-line-animation 2s infinite linear;
}

/* Define the animations */
@keyframes short-line-animation {
  0% {
    width: 0px;
  }
  100% {
    width: 160px; 
  }
}

@keyframes long-line-animation {
  0% {
    width: 160px;
    
  }
  100% {
    width: 0px;
    
  }
}
@media screen and (max-width: 580px) {
  .headings{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
