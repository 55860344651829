:root {
    --primaryBlue: #002244;
    --secondryColor: #3A98B9;
    --thirdColor: #6963fe;
    --forthColor: #92dcff;
}

.foot {
    width: 100%;
    min-height: 40vh;
    background-color: var(--primaryBlue);
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid white;
    padding: 20px;
    color: var(--font-color);
    /* margin-top: 20px; */

}

.sonnenteach {
    width: 23%;
    min-height: 38vh;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    padding: 10px;
    /* justify-content: space-evenly; */
    /* align-items: center; */

}

.sonnenteach p {
    color: white;
    font-size: 18px;
    /* word-spacing: 5px; */
}

.sonnenteach img {
    width: 170px;
    height: 70px;
    background-color: white;
    border-radius: 5px;
}

.sonnenteach img:hover{
    transform: scale(1.1);
    transform: translateX(5px);
    transition: transform 0.2s ease-in-out;
}

.quick li:hover,
.platfrom li:hover {
    transform: scale(1.1);
    transform: translateX(5px);
    text-decoration: underline rgb(27, 238, 245);
    transition: transform 0.2s ease-in-out;

}

.quick {
    width: 23%;
    height: 45vh;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(255, 255, 255, 0.219) 0px 1px 0px, rgba(255, 255, 255, 0.322) 0px 0px 8px;
    border-radius: 5px;
    padding: 10px;
    color: white;
}

.quick li {
    list-style: none;
}


.quick a {
    text-decoration: none;
    color: white;
}

.platfrom {
    width: 23%;
    height: 45vh;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
    padding: 10px;
    box-shadow: rgba(255, 255, 255, 0.219) 0px 1px 0px, rgba(255, 255, 255, 0.322) 0px 0px 8px;
        border-radius: 5px;
    color: white;
}

.platfrom li {
    list-style: none;
    color: white;
}

/* .platfrom li:hover {
    transform: scale(1.05);
    transform: translateX(5px);
    text-decoration: underline rgb(27, 238, 245);;
    transition: transform 0.5s ease-in-out;
} */

.platfrom a {
    text-decoration: none;
    color: white;
}

.newstellers {
    width: 23%;
    height: 45vh;
    /* background-color:white; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
    padding: 10px;
    box-shadow: rgba(255, 255, 255, 0.219) 0px 1px 0px, rgba(255, 255, 255, 0.322) 0px 0px 8px;
        border-radius: 5px;
    color: white;
}

.newstellers p {
    color: white;

}

.newstellers img {
    height: 15vh;
}

.copyright {
    padding: 10px;
    width: 100%;
    height: 12vh;
    background-color: var(--primaryBlue);
    margin: auto;
    display: flex;
    justify-content: center;
    /* position: relative;
    top: 15px; */
    color: white;
    align-items: center;
    text-align: center;
}

.copyright a {
    text-decoration: none;
    color: var(--forthColor);
}

.copyright a:hover {
    text-decoration: underline;
}

@media screen and (max-width:1000px) {
    .address {
        display: none;
    }

    .message {
        width: 100%;
    }

    .details input {
        font-size: 10px;
    }

    .addresss {
        display: block;
    }

    .foot {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sonnenteach {
        width: 70%;
    }

    /* .sonnenteach img{
           width: 30%;
       } */
    .quick {
        width: 70%;
    }

    .platfrom {
        width: 70%;
    margin: 20px 0px;
    align-items: center;
    }

    .newstellers {
        width: 70%;
    }
}

@media screen and (max-width:600px) {

    .sonnenteach p {
        padding-bottom: 15px;
    }

    .addresss {
        font-size: 10px;
    }

    .addresss i {
        font-size: 30px;
    }



    .sonnenteach {
        width: 100%;
    }

    /* .sonnenteach img{
        width: 50%;
    } */

    .quick {
        width: 100%;
        align-items: center;
    }

    .platfrom {
        width: 100%;
        align-items: center;
    }

    .newstellers {
        width: 100%;
        align-items: center;
    }



}