.mainSlider{
    white-space: nowrap;
    width: 100%;
    height: 100vh;
    margin-top: 22px;
    position: relative;
}

.sliderImage{
    display: inline-block;
    width: 100vw;
    height: 100vh;
    color: white;
    position: relative;
}

.arrowL{
    position: absolute;
    top: 50%;
    /* left: 10px; */
    color: antiquewhite;
    font-size: 60px;
    z-index: 1;
    border: 1px solid white;
    opacity: 0;
}

.arrowL:hover{
    opacity: 0.8;
}
.arrowR{
    position: absolute;
    top: 50%;
    right: 10px;
    color: antiquewhite;
    font-size: 60px;
    z-index: 1;
    opacity: 0;
    border: 1px solid white;
}
.arrowR:hover{
    opacity: 0.8;
}

.itemTech{
    /* margin-top: 150px; */
    position: absolute;
    top: 300px;
    margin-left: 20px;
    width: 40%;
    text-align: justify;
    line-height: 25px;
}


@media screen and (max-width:600px) {
    .mainSlider{
        height:80vh;
    }

    .sliderImage{
        height: 80vh;
        border-radius: 125px 125px 35px 35px;
    }

    .itemTech{
        top:130px;
        width: 70%;
    }
    .itemTech h1{
        text-wrap: wrap;
        font-size: larger;
    }
    
}