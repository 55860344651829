:root {
    --primaryBlue: #002244;
    --secondryColor: #3A98B9;
    --thirdColor: #6963fe;
    --forthColor: #92dcff;
}
/* .backgrounds{
  background: rgba(0, 0, 0, 0.3)
  url("./img/homeBack.jpg");
background-blend-mode: darken;
background-repeat: no-repeat;
background-size: cover;

} */

.head {
    width: 100vw;
    /* background-image: url(./img/homeBack.jpg); */
    background: #f5f5f5;
    max-width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    transition: opacity 1s ease-in-out;
    /* overflow: hidden; */
    z-index: 2;
    position: fixed;
    top: 0;
}
 
.head img {
    width: 180px;
    height: 80px;
    margin-left: 2%;
}

.burger {
    display: none;
    margin: 10px 20px;
}

.burger div {
    width: 30px;
    height: 3px;
    margin: 5px;
    background-color: var(--primaryBlue);
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
}



.navbar {
    width: calc(500px + 20%);
    height: 140px;
    position: relative;
}

.navbar ul {
    /* display: flex !important; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--backgroundC); */
    border-radius: 90px 0px 0px 2px;
}

.navbar ul li:hover {
    margin-bottom: 3px;
    /* text-decoration: underline; */
    color: var(--thirdColor);
}

.navbar ul li {
    list-style: none;
    margin: 0px 18px;
}



.navbar ul li a {
    text-decoration: none;
    color: var(--fontC);
}
.navbar ul li a:hover {
  text-decoration: none;
  /* color: var(--fontC); */
}

/* rotating navbar */
.rotating-navbar {
    width: 100%;
    height: 80px; /* Adjust the height as needed */
    /* background-color: #333; Customize the background color */
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    z-index: 999; /* Ensure it's on top of other content */
    /* color: white; Customize text color */
    /* animation-duration: 0.3s; */
    /* animation-timing-function: ease; */
    animation-fill-mode: forwards;
  }
  
  .active {
    /* animation-name: rotateDown; */
    transform-origin: top;
    backface-visibility: visible !important;
    animation-name: swingInX;
    animation-duration: .5s;
    animation-fill-mode: both;
    will-change: transform,opacity;
  }
  
  .hidden {
    transform-origin: top;
    backface-visibility: visible !important;
    animation-name: rotateUp;
    animation-duration: .5s;
    animation-fill-mode: both;
    will-change: transform,opacity;
    /* visibility: hidden; */
  }

  @keyframes rotateUp {
    0%{
      transform: perspective(400px) rotateX(0deg);
    }

    100%{
      transform: perspective(400px) rotateX(-90deg);
    }
  }

  @keyframes swingInX{
    0%{
      transform: perspective(400px) rotateX(-90deg);
    }

    100%{
      transform: perspective(400px) rotateX(0deg);
    }
  }



@media All and (max-width:830px) {

    .rotating-navbar{
        height: auto;
      }

    .navbar {
        background-image: url(./img/homeBack.jpg);
        background-size: 100% 100%;
        display: flex;
        position: fixed;
        width: 100vw;
        height: 60px;
        background-color: none;
        margin-left: -20px;
        /* height: 350px; */
        /* position: absolute; */
        z-index: 2;
        top: 68px;
        /* right: 0px; */
        overflow: hidden;
        transition: height 1s ease-in;
        font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",
    }

    .burger {
        display: block;
    }

    .navbar ul {
        flex-direction: column;
    }

    .burger {
        order: 1;
    }

    .navbar ul {
        border-radius: 0px 0px 0px 0px;
    }

    .navbar ul li {
        list-style: none;
        margin: 10px 0px;
    }


}
