@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato&family=Open+Sans:wght@300;400&display=swap');

 

@font-face {
  font-family: Montserrat;
  src: url("./Assets/Fonts/static/Montserrat-Medium.ttf");
}


:root {
  --primaryBlue: #002244;
 --secondryColor: #3A98B9;
  --thirdColor: #6963fe;
  --forthColor: #92dcff;
}


/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
} */
*, *::before, *::after {
  box-sizing: inherit;
  /* font-family: 'Montserrat', sans-serif; */
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x : inherit;
  /* text-align: justify; */
 /* font-family: 'Lato', sans-serif; */
font-family: 'Montserrat', sans-serif; 
background-color: rgb(244, 250, 255);
}
