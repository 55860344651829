:root {
  --primaryBlue: #002244;
  --secondryColor: #3a98b9;
  --secondryLightColor: rgb(248, 248, 248);
  --thirdColor: #6963fe;
  --forthColor: #92dcff;
}

.container_services {
  max-width: 100vw;
  height: 45vh;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container h1 {
  font-size: 50px;
}

.container-page {
  max-width: 100vw;
  min-height: 60vh;
  /* background-image: url(./img/Services_img/servicesbg.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: var(--primaryBlue);
}

.scroll-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 50px;
  padding: 10px;
}

.scroll-icon {
  cursor: pointer;
}
.service-box {
  width: 80vw;
  min-height: 50vh;
  margin: auto;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr; */
  overflow-x: scroll;
  scroll-behavior: smooth;
  
}
.service-box::-webkit-scrollbar {
  /* width: 0.2em; */
  display: none;
}
.cellContainer {
  width: 80px;
  height: 120px;
  border-radius: 50%;
  /* background-image: linear-gradient(
    to right,
    rgb(242, 112, 156),
    rgb(255, 148, 114)
  ); */

  display: flex;
  justify-content: center;
  align-items: center;
}
.cellIcon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.box {
  box-sizing: border-box;
  margin: 17.5px;
  background-color: var(--secondryLightColor);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 15px; */
  text-align: center;
  /* transition: transform ease-in-out 0.3s; */
}

/* .box5 {
  grid-column: 2/3;
}

.box6 {
  grid-column: 3/4;
} */

.service-icon {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box h4 {
  margin-top: 20px;
}

.icon-bar {
  position: relative;
  left: 8%;
}

.icon {
  position: absolute;
  left: 20%;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  z-index: 1;
}

.box p {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  color: var(--secondary);
  font-family: var(--font);
  line-height: 35px;
}

.box a:last-child {
  text-decoration: none;
  color: var(--hovercolor);
  font-family: var(--font);
  font-size: 14px;
}

#heading {
  color: var(--third);
  font-size: 18px;
}

#heading:hover {
  color: var(--hovercolor);
}

.box:hover {
  border: 1px solid var(--hovercolor);
  border: 1.5px solid var(--forthColor);
  transform: scale(1.05);
}

.box button {
  width: calc(100px + 7vw);
  margin: 10px;
  height: 40px;
  background-color: #3a98b9;
  color: white;
  border-radius: 10px;
  border: none;
  outline: none;
}



@media screen and (max-width: 600px) {
  .service-box {
  scroll-behavior: smooth;
  width:52vw;
  min-height: 5vh;
  }

  .container-page{
    min-height: 38vh;
  }
  .service-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box5 {
    grid-column: 1/2;
  }

  .box6 {
    grid-column: 1/2;
  }

  .icon-bar {
    left: 7%;
  }

  .icon {
    left: 15%;
  }
}
