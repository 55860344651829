* {
    margin: 0;
    padding: 0;
}

:root {
    --primaryBlue: #002244;
    --secondryColor: #3A98B9;
    --thirdColor: #6963fe;
    --forthColor: #92dcff;
}

.gallery {
    max-width: 100vw;
    min-height: 190vh;
    background-color: white;
    display: grid;
    margin: auto;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    column-gap: 15px;
    row-gap: 40px;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    background-image: url(./img/Gallery_img/background.jpg);
    background-size:50% 100%;
    background-attachment: fixed;
    padding: 20px;
    
}


.gallery img {
    /* width: 300px;
    height: 280px; */
    transition: all ease-in-out .35s;
    transform: scale(1);
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: 5px solid rgb(148, 140, 140);
    box-shadow: 5px 5px 10px black;
}

.gallery img:hover {
    transform: scale(1.1);
    border-radius: 20%;
}

@media All and (max-width:1040px) {
    .gallery {
        grid-template-columns: auto auto auto;
    }

    .gallery :last-child {
        display: none;
    }

}

@media All and (max-width:770px) {
    .gallery {
        grid-template-columns: auto auto;

    }

    .gallery :last-child {
        display: block;
    }
}


@media All and (max-width:530px) {
    .gallery {
        grid-template-columns: auto;

    }

}