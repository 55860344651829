
.teamContainer {
  width: 100vw;
  /* height: 100vh; */
  margin-bottom: 30px;
  margin-top: 100px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.headBar {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.headBar h1{
    font-size: 34px;
    font-weight: 600;
}
.headBar h1{
    font-size: 34px;
    font-weight: 600;
}
.headBar p{
   font-size: 16px;
    font-weight: 500;
}
.teamMemberBar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 100px;
  margin-top: 30px;

}

.indivBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.indivBox h1{
    color: #3a98b9;
    font-size: 24px;
    font-weight: 500;
}
.indivBox p{
    color: gray;
    font-size: 14px;
    font-weight: 400;
}

.r-hex {
  overflow: hidden;
  display: inline-block;
  margin: 1em 0;
  width: 250px;
  height: 250px;
  transform: rotate(-90deg) skewX(30deg);
  border-radius: 0.5em;
}
.r-hex *,
.r-hex *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}
.r-hex-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  opacity: 1;
  transition: opacity 0.75s;
  cursor: pointer;
}
.r-hex:first-child .r-hex-inner:before,
.r-hex-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  /* background: orange; */
 object-fit:cover;
}

.r-hex-inner-2:before {
  margin-top: -1.5em;
  height: 20em;
  transform: skewX(-60deg) rotate(-120deg);
  /* background: url(https://assets.codepen.io/2017/17_05_a_amur_leopard_34.jpg); */
  background-size: cover;
  line-height: 20em;
  text-align: center;
  
}
.r-hex-inner:hover {
  opacity: 1;
}
