/* :root {
    --backgroundC: #ccd9f7;
    --fontC: #140c8b;
    --OtherC: yellow;
    --primary: #3A98B9;
    --secondary: #707b8e;
    --third: #3795BD;
    --thirdcolor: #3795BD;
    --colorwhite: #ECF2FF;
    --headingcolor: #2B044d;
    --font: 'Nunito Sans', sans-serif;
} */

:root {
  --primaryBlue: #002244;
  --secondryColor: #3a98b9;
  --thirdColor: #6963fe;
  --forthColor: #92dcff;
}

.cover_Detail {
  width: 100vw;
  /* background-image: url(./img/homeBack.jpg); */
  /* background-size: 100% 100%; */
  /* height: calc(315px + 50vh); */
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
  /* position: relative;
    top: 50px; */
}

.details {
  width: 40%;
  height: 60%;
  /* background-color: red; */
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  text-shadow: 1px 0px 0px rgb(243, 243, 243);
  color: var(--primaryBlue);
  /* background: #FFFFFF; */
  font-size: 3vw;
}

.details h4 {
  font-size: 0.7em;
  text-shadow: 0.5px 0.5px 5px var(--forthColor);
}

.details h1 {
  font-size: calc(0.8em + 16px);
}

.details h3 {
  font-weight: 500;
  font-size: 0.5em;
}

.coverPhoto {
  width: 50%;
  height: 80%;
  animation: coverMove alternate infinite 1.5s;
  animation-timing-function: linear;
}

.container_Home {
  width: 100vw;
  /* min-height: 120vh; */
  display: flex;
}

.container-left {
  /* width: 40%;
  height: 50vh; */
}

.container-left img {
  width: 50%;
  /* height: auto; */
  /* margin: auto;
  margin-top: 25px; */
}

.container-right {
  /* width: 100%; */
  /* min-height: 120vh; */
  /* background-color: var(--secondary); */
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  /* padding-bottom: 10px; */
}

.rightbox1 {
  width: 35%;
  /* height: 40vh; */
  /* background-color: var(--colorwhite); */
  color: var(--primaryBlue);
  margin-bottom: 150px;
  font-size: 1.3em;
  font-weight: 700;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
}

.rightbox2 {
  width: 55%;
  /* margin-right: 20px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /* gap: 10px; */
}

.commerce-box {
  display: flex;
}

.commerce-box img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 20px;
}

.commerce-box h2 {
  margin: 20px;
  color: var(--primaryBlue);
}

.commerce-box p {
  margin: 20px;
  font-size: 16px;
  color: var(--primaryBlue);
  line-height: 25px;
  text-align: justify;
}

/* -------------------------------- */
.container-services {
  width: 100vw;
  text-align: center;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items:flex-end;
  font-size: 25px;
  padding: 10px;
}

.mobile-slide {
  max-width: 100%;
  min-height: 100%;
  /* background-color: var(--primary); */
  display: flex;
  font-size: 20px;
}

.mobile-left {
  width: 40%;
  height: inherit;
  /* background-color: var(--secondary); */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: justify; */
  margin-top: 65px;
}
 
.mobile-right {
  width: 60%;
  height: inherit;
  
}

.mobile-left h1 {
  width: 60%;
  color: var(--primaryBlue);
  font-size: 2em;
  font-weight: 700;
  margin-top: 20px;
}
.mobile-left h6 {
  width: 60%;
  color: var(--primaryBlue);
  font-size: 1em;
  font-weight: 600;
  margin-top: 10px;
}

.mobile-left p {
  width: 60%;
  color: var(--primaryBlue);
  font-size: 16px;
  margin-top: 20px;
  line-height: 25px;
  text-align: justify;
}

.mobile-right img {
  /* margin-bottom: 30px; */
  width: 90%;
}

.customer-container {
  max-width: 86.5vw;
  border-radius: 10px;
  margin: 10px auto;
  height: 28vh;
  /* background-color: red; */
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--primaryBlue);
  padding: 0 10px;
}



.customer-card1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  width: 25%;
  box-sizing: border-box;
  height: inherit;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.customer-card1 img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.customer-card1 h2 {
  color: var(--primaryBlue);
}

.customer-card1 p {
  margin-top: 20px;
  color: var(--primaryBlue);
  font-size: 18px;
  line-height: 25px;
}

/* -------------------- */

.application {
  max-width: 100vw;
  min-height: 80vh;
  /* background-color: #3A98B9; */
  display: flex;
  background-image: -webkit-linear-gradient(0deg, #7f54cd, #5697dd);
}

.application-left {
  width: 50%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.application-right {
  width: 50%;
  height: inherit;
  display: flex;
  align-items: center;
}

.application-right img {
  width: 100%;
  height: 60%;
}

.application-img {
  margin-left: 50px;
}

.application-img img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.application-left h1 {
  color: var(--primaryBlue);
  margin-left: 50px;
  font-size: 50px;
  font-weight: 700;
}

/* --------------------------------- */
.contact-us {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px auto;
  max-width: 90vw;
  height: 40vh;
  /* background-image: -webkit-linear-gradient(0deg, #6425da, #5697dd); */
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 10px;
}
.contactUsHead{
  width: 100%;
  display: flex ;
  justify-content: center;
  align-items: center;
margin-top: 30px;
color: var(--primaryBlue);
  font-size: 25px;
  font-weight: 600;
}
.contact-us h1 {
  color: var(--primaryBlue);
  font-size: 25px;
  font-weight: 600;
  margin-left: 20px;
}

.contact-us p {
  width: 200px;
  height: 50px;
  color: -webkit-linear-gradient(
    0deg,
    rgb(166, 0, 255),
    rgb(40, 147, 234)
  );
  
  border: none;
  border-radius: 10px;
  font-size: 20px;
  /* color: white; */
  font-weight: 500;
  margin-right: 50px;
  cursor: pointer;
  margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  .rightbox1 {
    width: 100%;
  }

  .rightbox2 {
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 800px) {
  .mobile-slide {
    font-size: 15px;
  }

  .mobile-left h1 {
    width: 90%;
  }

  .mobile-left p {
    width: 90%;
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .container_Home {
    flex-direction: column;
  }

  /* .container-left {
    width: 100%;
    height: 70vh;
  } */

  .container-right {
    width: 100%;
    flex-direction: column;
  }

  .container-services {
    font-size: 14px;
  }

  .rightbox1 {
    max-width: 100%;
    height: 20vh;
    font-size: 0.9em;
    /* display: flex; */
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }

  .rightbox2 {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    grid-template-columns: 1fr;
  }

  .customer-card {
    flex-direction: column;
  }

  .customer-card1 {
    width: 100%;
  }

  .application {
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    text-align: center;
    min-height: 80vh;
  }

  .application-left {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .application-left h1 {
    font-size: 40px;
    margin: 50px 0 0 0;
  }

  .application-right {
    width: 100%;
    justify-content: center;
  }

  .application-img img {
    width: 100px;
    height: 100px;
    border: 50%;
  }

  .application-right img {
    width: 50%;
    height: 60%;
  }

  .application-img {
    margin: 50px 0 50px 0;
  }
}

@media screen and (max-width: 700px) {
  .mobile-slide {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .mobile-left {
    width: 100%;
    text-align: center;
  }

  .mobile-left h1 {
    margin-top: 10px;
  }
  .mobile-left h6 {
   font-size: 1.5em;
  }

  .mobile-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-right img {
    margin-top: 10px;
  }

  .customer-container {
    font-size: 18px;
  }

  /* --------------------------------- */
  .contact-us {
    max-width: 100vw;
    height: 50vh;
    flex-direction: column;
  }

  .contact-us h1 {
    text-align: center;
    font-size: 40px;
    margin: 0 10px;
  }

  .contact-us button {
    margin: 0 10px;
  }
}

@media screen and (max-width: 425px) {
  .commerce-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@keyframes coverMove {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media all and (max-width: 600px) {
  .cover_Detail {
    flex-direction: column-reverse;
    /* padding: 50px 0px; */
    height: 80vh;
    justify-content: space-evenly;
  }

  .details {
    width: 75%;
    height: 40%;
  }

  .details h4 {
    font-size: 1.3em;
  }

  .details h1 {
    font-size: 1.7em;
  }

  .details h3 {
    font-weight: 500;
    font-size: 1em;
  }

  .coverPhoto {
    width: 60%;
    height: 50%;
  }
}
/* Importing fonts from Google */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

/* Reseting */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
}

.row {
  background: linear-gradient(to bottom, #000428, #004683);
  animation: background-color 20s;
  min-height: 60vh;
  padding: 10px;
}

@keyframes background-color {
  0% {
    background: linear-gradient(to bottom, #000428, #004683);
  }

  25% {
    background: linear-gradient(135deg, #1a4223, #5ee95e);
  }

  50% {
    background: linear-gradient(to bottom, #421a31, #e95ed2);
  }

  100% {
    background: linear-gradient(-135deg, #fae37d, #881da8, #20668f);
  }
}

.container {
  margin-top: 80px;
}

.container .col-lg-4 {
  display: flex;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card {
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  perspective: 500px;
  border: none;
  background-color: inherit;
}

.card .face {
  position: absolute;
  color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.06);
  transform-style: preserve-3d;
  transition: 0.5s;
  backface-visibility: hidden;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  /* border-right: 1px solid #999;
border-bottom: 1px solid #999; */
}

.card .face.front-face,
.card .face.back-face {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card .face.front-face .profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.card .face.front-face .name {
  letter-spacing: 2px;
}

.card .face.front-face .designation {
  font-size: 0.8rem;
  color: #ddd;
  letter-spacing: 0.8px;
}

.card:hover .face.front-face {
  transform: rotateY(180deg);
}

.card .face.back-face {
  position: absolute;
  background: rgba(255, 255, 255, 0.06);
  transform: rotateY(180deg);
  padding: 20px 30px;
  text-align: center;
  user-select: none;
}

.card .face.back-face .fa-quote-left {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 1.2rem;
}

.card .face.back-face .fa-quote-right {
  position: absolute;
  bottom: 35px;
  right: 25px;
  font-size: 1.2rem;
}

.card:hover .face.back-face {
  transform: rotateY(360deg);
}

@media (max-width: 991.5px) {
  .col-lg-4 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
