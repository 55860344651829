:root {
    --primaryBlue: #002244;
    --secondryColor: #3A98B9;
    --thirdColor: #6963fe;
    --forthColor: #92dcff;
}
.bgimage {
    max-width: 100vw;
    min-height: 110vh;
    background-image: url(./img/Career_img/career1.webp);
    background-color: rgb(226, 226, 226);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
   
}

.join {
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 80px;
  
  
}

.heading {
    max-width: 100vw;
    /* min-height: 120px; */
    margin: 10px 0px;
    /* opacity: 0; */
    /* transform: translateY(20px); */
    /* transition-delay: 1s;
    transition: opacity 0.5s, transform 1s; */
}

.heading h1 {
    text-align: center;
    color: black;
    font-size: 40px;
    /* text-shadow: 0.5px 0.5px 10px blue; */
    font-family: var(--font);
}
  
  .visible {
    opacity: 1;
    transform: translateY(0);
  }
.carrerPara{
    width: 500px;
    text-align: justify;
}
.carrerPara p{
    /* font-weight: 600; */
    
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.5), 1px -1px 0 rgba(255, 255, 255, 0.5), -1px 1px 0 rgba(255, 255, 255, 0.5), 1px 1px 0 rgba(255, 255, 255, 0.5);
}

.mainbox {
    width: 90vw;
    margin-left: auto;
    display: flex;
    margin-right: auto;
    justify-content: space-between;
    
}

.services-box{
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: column;
    color: black;
    float: left;
    margin-top: inherit;
}
.services-box h1{
    margin: 10px;
    font-size: 40px;
}
.services-box h2{
    margin: 5px 10px;
    font-size: 35px;
    color: rgb(7, 0, 199);
}

.services-box p{
margin: 3px 10px;
font-size: 16px;
text-align: justify;
}

.services-box button{
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 10px;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(10px);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.services-box button:hover{
    cursor: pointer;
   background-color: #fff;
   color: #405cf5;
   border: 1px solid #405cf5;
}

@media screen and (max-width:600px) {
 .bgimage{
    min-height: 65vh;
    background-size: 100% 100%;
 }
 .join{
    min-height: 50vh;
 }
 .services-box p{
    width: 70%;
 }
 .heading h1{
    margin: 10px;
    font-size: xx-large;
}
    
}
